import cx from 'classnames';
import React, { useState } from 'react';

import styles from './style.module.scss';

const isDesktop = () => document.body.clientWidth > 991;
const partnerSize = () => isDesktop() ? 5: 4;

export default function PartnerNav({ children }) {

  const [horseList, setHorseList] = useState(children);
  const [moveDirection, setMoveDirection] = useState('');
  const [align, setAlign] = useState('alignPrev');

  const handleMove = direction => {
    const step = isDesktop() ? 1: 2;
    if(direction === 'nextMoving') {
      setTimeout(() => {
        if(isDesktop() ? true : align === 'alignNext') {
          setHorseList([
            ...horseList.slice(step),
            ...horseList.slice(0, step)
          ]);
        }
        setMoveDirection('');
        setAlign('alignNext');
      }, 300);
    } else {
      if(isDesktop() ? true : align === 'alignPrev') {
        setHorseList([
          ...horseList.slice(-step),
          ...horseList.slice(0, horseList.length - step),
        ]);
      }
      setTimeout(() => {
        setMoveDirection('');
        setAlign('alignPrev');
      }, 300);
    }
    setMoveDirection(direction);
  };

  return (
    <div className={styles.PartnerNav}>
      {horseList.length >= partnerSize() && <>
        {
          //(isDesktop() ?
          //  children[0] !== horseList[0]:
          //  align === 'alignNext' ?
          //    children.length > 4:
          //    horseList[0] !== children[0]
          //) &&
          <div className={styles.prev} onClick={handleMove.bind(this, 'prevMoving')}>
            <div className={styles.arrow} />
          </div>
        }
        {
          //(isDesktop() ?
          //  children[children.length - 1] !== horseList[4]:
          //  align === 'alignPrev' ?
          //    true: ///???
          //    horseList[4] !== children[children.length - 2]
          //) &&
          <div className={styles.next} onClick={handleMove.bind(this, 'nextMoving')}>
            <div className={styles.arrow} />
          </div>
        }
      </>}
      <div className={cx(
        styles.road,
        styles[align],
        styles[moveDirection]
      )}>
        {horseList.map((horse, index) =>
          <div key={index} className={styles.horse}>
            {horse}
          </div>
        )}
      </div>
    </div>
  );
}
