import O18N from 'o18n';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Banner } from 'components';
import { Carousel, PartnerNav } from './components';

import {
  XHR,
  PATH,
} from 'consts';

// import { uvCode } from 'utils';

import bannerImg from 'images/bannerHome@2x.png';

import locale from './locale';
import Globallocale from 'locale';
import headerLocale from 'containers/App/components/Header/locale';
import styles from './style.module.scss';

const Home = ({ parentClass, isOpenMenu, showBoard, marquees, weather, lang }) => {
  const [ data, setData ] = useState({});
  const t = new O18N(Globallocale, lang);
  t.add(locale);
  t.add(headerLocale);

  useEffect(() => {
    fetch(XHR.INFO)
      .then(res => res.json())
      .then((json) => {
        setData(json);
      });
  }, []);

  return (
    <>
      <div className={cx(styles.swiperBanner1)} style={{backgroundImage: `url(${data.Banner1})`}}></div>
      <div className={cx(styles.swiperBanner2)} style={{backgroundImage: `url(${data.Banner2})`}}></div>
      <Banner showHint showBoard {...{url: bannerImg, weather, marquees, lang }} />
      <div className={cx(
        "container",
        parentClass.container,
        {[parentClass.shift]: isOpenMenu}
      )}>
        <div className={styles.Home}>
          <div className={cx("row", styles.row, styles.block1)}>
            <div className={cx("col-md-6")}>
              {!!data.Activities && !!data.Activities.length &&
                <Carousel showDots>
                  {data.Activities.map((item, index) =>
                    <Link key={`a${index + 1}`} to={`${PATH.NEWS}${PATH.ACTIVITY}/${item.SN}`}>
                      <div
                        className={styles.img}
                        style={{
                          backgroundImage: `url(${item.PictureUrl})`,
                          backgroundSize: 'cover'
                        }}
                      />
                      <div>{item.Title}</div>
                    </Link>
                  )}
                </Carousel>
              }
            </div>
            <div className={"col-md-6"}>
      
              <div className={cx(styles.typeNews, styles.list)}>
                {!!data.News && !!data.News.length &&
                  <ul>
                    {data.News.map((item, index) =>
                      <li key={`news-${index}`}>
                        <div className={styles.news}>
                          <div
                            className={cx(
                              styles.date,
                              styles.type,
                              styles[Object.keys(t)[item.Category - 1]]
                            )}
                            data-type={
                              t[Object.keys(t)[item.Category - 1]]
                            }
                          >{item.Postday.slice(0, 10)}</div>
                          <div className={styles.intro}>
                            <Link to={`News${PATH.ANNOUNCEMENT}/${item.SN}`} dangerouslySetInnerHTML={{ __html: item[`Title_${lang}`]}}></Link>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                }
                <Link className={styles.more} to={PATH.NEWS}>{t.moreNews}</Link>
              </div>
            </div>
            
          </div>
          <div className={cx("row", styles.rowBlock)}>
            <div className={cx(styles.df, "col-md-6")}>
              <div className={styles.block}>
                <div className={styles.title}>{t.landscape}</div>
                <Carousel showDots dotsClass={styles.dotsClass}>
                  <iframe title="i2" key="i2" width="100%" height="250" src="https://www.youtube.com/embed/eldrkC3PH28" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  <iframe title="i3" key="i3" width="100%" height="250" src="https://www.youtube.com/embed/Iyz0H5_Q6mI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Carousel>
              </div>
            </div>
            <div className={cx(styles.df, "col-md-3", styles.last)}>
              <div className={cx(styles.block, styles.externalLinks)}>
                <div className={styles.title}>{t.links}</div>
                <div className={styles.list}>
                  {!!data.Links && !!data.Links.length &&
                    <ul>
                      {data.Links.map(item =>
                        <li key={item.LinkId}>
                          <a rel="noopener noreferrer" href={item.Url} target="_blank">{item.LinkTitle}</a>
                        </li>
                      )}
                    </ul>
                  }
                  {/* <Link className={styles.more} to="/">{t.moreLinks}</Link> */}
                </div>
              </div>
            </div>
            <div className={cx(styles.df, "col-md-3", "order-md-first", styles.first)}>
              <div className={cx(styles.block, styles.mb20, styles.liveEnv)}>
                <div className={styles.title}>{t.weather}</div>
                {data.AirQuality &&
                  <ul>
                    <li>
                      <div>{t.aq}</div>
                      {/* <div>{t[`aq${data.AirQuality.AQ}`]}</div> */}
                      <div className={styles[`aq${data.AirQuality.AQ}`]} />
                    </li>
                    <li>
                      <div>{t.uv}</div>
                      {/* <div>{t[uvCode(data.AirQuality.UV)]}</div> */}
                      <div
                        className={styles.sun}
                        data-number={data.AirQuality.UV}
                      />
                    </li>
                    <li>
                      <div>野柳實況</div>
                      <a target="_blank" href="https://www.youtube.com/watch?v=EICbp1H7l6c">
                        <div className={styles.youtubeLive}></div>
                      </a>
                    </li>
                  </ul>
                }
              </div>
              {/* <div className="fb-page" 
                data-href="https://www.facebook.com/yehliugeopark"
                data-height="170" 
                data-hide-cover="false"
                data-show-facepile="false"></div> */}
            </div>
          </div>
          <div className={cx(styles.rowBlock)}>
            <div className={cx(styles.block, styles.sister)}>
              <div className={styles.title}>{t.sister}</div>
              {data.FriendlyParks && !!data.FriendlyParks.length &&
                <PartnerNav>
                  {data.FriendlyParks.map(item =>
                    <div key={`item${item.ParkId}`} className={styles.item}>
                      <a href={item.Url}>
                        <div
                          className={styles.img}
                          style={{backgroundImage: `url(${item.ParkImg})`}}
                        />
                      </a>
                      <div className={styles.text}>{item.Title}</div>
                    </div>
                  )}
                </PartnerNav>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;