import cx from 'classnames';
import React, { useEffect, useState, useRef } from 'react';

import styles from './style.module.scss';

const NEXT_TIME = 3000;

export default function Carousel({ children, showDots, dotsClass }) {
  const savedCallback = useRef();
  const [isMove, setIsMove] = useState(false);
  const [horseList, setHorseList] = useState(children);
  const activeIndex = children.indexOf(horseList[0]);
  let interval = useRef();
  const move = () => {
    setTimeout(() => {
      setHorseList([
        ...horseList.slice(1),
        horseList[0]
      ]);
      setIsMove(false);
    }, 300);
    setIsMove(true);
  };

  const handleClick = index => {
    setHorseList([
      ...children.slice(index),
      ...children.slice(0, index),
    ]);
  };

  const tick = () => {
    savedCallback.current();
  };

  const handleEnter = () => {
    clearInterval(interval.current);
  };

  const handleLeave = () => {
    interval.current = setInterval(tick, NEXT_TIME);
  };

  useEffect(() => {
    savedCallback.current = move;
  });

  useEffect(() => {
    interval.current = setInterval(tick, NEXT_TIME);
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  
  return (
    <div className={styles.Carousel}>
      <div className={cx(styles.road, { [styles.move]: isMove })}>
        {horseList.map(horse =>
          <div
            key={horse.key}
            className={styles.horse}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
          >
            {horse}
          </div>
        )}
      </div>
      {showDots &&
        <ul className={cx(styles.dotsBlock, dotsClass)}>
          {horseList.map((horse, index) =>
            <li
              key={horse.key}
              onClick={handleClick.bind(this, index)}
              className={cx({
                [styles.active]: index === activeIndex
              })}
            />
          )}
        </ul>
      }
    </div>
  );
}

Carousel.defaultProps = {
  showDots: false,
  showArrow: false,
  autoStart: true,
};